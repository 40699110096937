.enticementColumn {
  margin-top: 150px;
}

.signupColumn {
  margin-top: 50px;
}

.overlayText {
  position: relative;
  bottom: 60px;
  left: 240px;
  color: white;
  font-size: 18px;
}

.profileActionsCard {
  width: 350px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.userCard {
  background-color: white;
}

.userCard .card-header {
  background-color: white;
  border: 0px;
}

.userCard .card-header hr {
  margin: 0px;
}

.userCard .card-body {
  padding-top: 0px;
}

.actionMenuItem {
  padding: 0;
}

.editProfileLabel {
  float: left;
  font-size: 16px;
  background-color: white;
}

.editProfileLabel a:hover {
  cursor: pointer;
  color: red;
}

.editProfileLabel i {
  color: green;
}

.fb-profile img.fb-image-lg{
    z-index: 0;
    width: 100%;
    margin-bottom: 10px;
}

.fb-image-profile
{
    margin: -90px 10px 0px 50px;
    z-index: 9;
    width: 20%;
}

@media (max-width:768px)
{
  .fb-profile-text>h1{
      font-weight: 700;
      font-size:16px;
  }

  .fb-image-profile
  {
      margin: -45px 10px 0px 25px;
      z-index: 9;
      width: 20%;
  }
}

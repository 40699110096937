.friendCard {
  width: 400px;
  margin-bottom: 5px;
}

.friendName {
  color: #007b5e !important;
}

.friendDescription {
  margin-bottom: 5px;
}

/**Reset Bootstrap*/
.dropdown-toggle::after {
    content: none;
    display: none;
}

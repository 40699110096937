span
{
  font-size:10px;
  float:right;
  background-color:#F4F7F9;
  border-radius:10px;
  padding:3px 8px;
  color:#84919B;
}

.friendCol {
  padding: 10px;
  width: 200px;
}

.friendCard {
  padding-bottom: 10px;
  padding-top: 10px;
}

.friendsActionsCard {
  width: 350px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.friendName {
  color: #007b5e !important;
}

.friendDescription {
  margin-bottom: 5px;
}

.groupCard {
  width: 100%;
  margin-bottom: 5px;
  background-color: #f4f5f7;
}

.groupName {
  color: #007b5e;
}

groupName a:hover {
  color: #red !important;
}

.groupDescription {

}

.groupMemberCount {
  font-style: italic;
  position:absolute;
  bottom:0;
  right:0;
}

.feedActionsCard {
  width: 350px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.actionMenuItem {
  padding: 0;
}

.createActivityLabel {
  float: left;
  font-size: 16px;
  background-color: white;
}

.createActivityLabel a:hover {
  cursor: pointer;
  color: red;
}

.createActivityLabel i {
  color: green;
}

/* Larger svreens */
@media (min-width: 576px) {
  .contentColumn {
    margin-top: 0px;
  }

  .adsColumn {
    margin-top: 0px;
  }

  .eventCard {
    margin-bottom: 15px;
  }
}

/* Small screens */
@media (max-width: 576px) {
    .adsColumn {
      margin: 0px;
      padding: 0px;
    }

    .contentColumn {
      margin: 0px;
      padding: 0px;
    }
}

.createNewCard {
  padding: 5px;
  width: 350px;
  margin-bottom: 15px;
}

.h7 {
    font-size: 0.8rem;
}

.gedf-wrapper {
    margin-top: 0.97rem;
}

@media (min-width: 992px) {
    .gedf-main {
        padding-left: 4rem;
        padding-right: 4rem;
    }
    .gedf-card {
        margin-bottom: 1.0rem;
    }
}

/**Reset Bootstrap*/
.dropdown-toggle::after {
    content: none;
    display: none;
}

.groupsActionsCard {
  width: 350px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.groupsTab {
  background-color: rgba(0,0,0,.05);
}

.actionMenuItem {
  padding: 0;
}

.createGroupLabel {
  float: left;
  font-size: 16px;
  background-color: white;
}

.createGroupLabel a:hover {
  cursor: pointer;
  color: red;
}

.createGroupLabel i {
  color: green;
}

.infinite-list {
  padding: 0;
}

.infinite-list .controls {
  width: 100%;
  margin: 0px 0px;
}

.infinite-list .form-label {
  margin-right: 5px;
}

.card-body{
  padding-top: 0;
  padding-bottom: 5px;
}

.feed-card .card-header {
  background-color: white;
  border: 0;
}

.card-link {
  color: #535456;
}

.card-text {
  margin-top: 10px;
}

.card-title {
  margin-bottom: 0;
}

.card-link:hover {
  cursor: hand;
  color: black;
}

.header-row {
  padding: 0;
  margin: 0;
  height: 23px;
}

.add-comment-input {
  border-radius: 18px !important;
  background: #eaedf2;
  margin-left: 5px;
}

.comment-input-row {

}

@media (min-width: 992px) {
    .feed-card {
        margin-bottom: 1.0rem;
    }
}

/**Reset Bootstrap*/
.dropdown-toggle::after {
    content: none;
    display: none;
}

.h7 {
    font-size: 0.8rem;
}

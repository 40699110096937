.groupRow {
  width: 100%;
  border-bottom: 1px;
}

.groupRow:last-child {
  border-bottom: 0px;
}

.groupRow:hover {
  background-color: #f4f6f9;
}

.groupMemberCount {
  font-style: italic;
  position:absolute;
  bottom:0;
  right:0;
}

.btn-flat.btn-flat-icon {
  border-radius: 50%;
  font-size: 16px;
  height: 24px;
  width: 24px;
  padding: 0;
  overflow: hidden;
  color: #fff !important;
  background: #b5b6b6;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  border-top: 0;
  border-right: 0;
  border-bottom: 0;
  border-left: 0;
}

.comment-row {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.comment-content {
  margin-left: 5px;
  border-radius: 18px !important;
  background: #efeded;
  padding: 10px;
}

.comment-date {
  margin-left: 5px;
  margin-top: 12px;
}

.thumbnail-column {
  max-width: 25px;
  padding: 0;
  margin: 0;
}

.comment-column {
  padding: 0;
  margin: 0;
}

.groupActionsCard {
  width: 350px;
  margin-bottom: 15px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.groupCard .card-title {
  padding-left: 20px;
  padding-top: 15px;
  margin: 0px;
}

.groupCard .card-body {
  padding: 0px;
  padding-left: 20px;
  padding-bottom: 15px;
}

.actionMenuItem {
  padding: 0;
}

.createActivityLabel {
  float: left;
  font-size: 16px;
  background-color: white;
}

.createActivityLabel a:hover {
  cursor: pointer;
  color: red;
}

.createActivityLabel i {
  color: green;
}

.groupName {
  font-family: 'Rokkitt', serif;
  font-weight: bold !important;
  font-size: 20px;
  color: #292e38;
}

.groupDescription {
  font-family: 'Rokkitt', serif;
  font-weight: bold !important;
  font-size: 12px;
  color: #555555;
}

.groupMemberCount {
  font-family: 'Rokkitt', serif;
  font-weight: italic !important;
  font-size: 12px;
  color: #555555;
  float: right;
  padding-right: 15px;
  padding-bottom: 10px;
}

.new-activity-btn {
  float: right;
}
